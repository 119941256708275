.Content {
  h1 {
    @extend %u-text--intro;
    strong {
      font-weight: bold;
    }
  }
  h2 {
    @extend %u-text--intro;
    padding-bottom: 0.5em;
  }
  h3 {
    @extend %u-text--smallCaps;
    font-weight: normal;
    padding-bottom: 0;
    text-transform: uppercase;
  }
  figure {
    display: block;
    min-height: 40px;
    margin-bottom: 15px;
  }
  p,
  li {
    @extend %u-text--body;
    padding-bottom: 15px;
    strong {
      font-weight: bold;
    }
  }
  h3,
  p,
  li {
    max-width: 700px;
  }

  h4,
  h5,
  h6 {
    @extend %u-text--body;
    font-weight: normal;
    max-width: 700px;
    strong {
      font-weight: bold;
    }
    padding-bottom: 15px;
  }
  h4 {
    font-weight: bold;
    padding-bottom: 0;
  }
  margin-right: auto;

  *:last-child {
    padding-bottom: 0;
  }

  a {
    border-bottom: 1px solid;
    &:hover {
      border-color: transparent;
    }
  }

  img {
    max-width: 200px;
  }

  ul {
    padding-left: 30px;
    li {
      list-style: disc;
      padding-bottom: 8px;
    }
    padding-bottom: 15px;
  }

  ol {
    padding-left: 30px;
    li {
      list-style: decimal;
      padding-bottom: 8px;
    }
    padding-bottom: 15px;
  }
}

.Content--small {
  max-width: 400px;
}
.Content.Content--small,
.Content.Content--caption {
  * {
    @extend %u-text--small;
  }
}

.Content--footer {
  ul {
    padding-left: 0;
    padding-bottom: 15px;
  }
  p,
  li {
    @extend %u-text--small;
    padding-bottom: 15px;
  }
  li,
  li:last-child {
    padding-bottom: 0 !important;
  }
}

// .Content--customdefault {
//   a {
//     color: #f79b2e;
//     border-bottom: none;
//   }
// }

.propeller {
	

	.Item .Item-head .Content *,
  .Item .Item-head .Content h3 {
    text-transform: none !important;
    letter-spacing: normal !important;
    font-size: 1.2rem !important;
    padding-bottom: 15px !important;
    // color: $pale-green-accent !important;

    strong, b {
      font-weight: 700;
      font-size: inherit;
    }
  }

  .Content h2 {
		//color: $pale-green-accent;

    strong, b {
      font-weight: 700;
      font-size: inherit;
    }
	}

  .Item .Item-head .Content a {
    border: none;
    text-decoration: underline;
  }

  &.Block--embed {
		border-bottom: none !important;
	}

	&.Block--embed .Block-iframe iframe {
		height: 90%;
		margin-top: 5%;
	}
}
